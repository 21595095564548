nav {
  position: fixed !important;
  top: 0;
  width: 100%;
  max-height: 108px;
  z-index: 9999;
  background: white;

  &.sft-with-banner {
    max-height: 200px;

    .navbar-collapse {
      @media (max-width: $laptop-width) {
        top: 124px;
      }
    }

    .dropdown-menu-services {
      @media (min-width: $laptop-width) {
        top: 150px !important;
      }
    }
  }
}

#navbar {
  @media (max-width: $laptop-width) {
    position: absolute;
    top: 65px;
    width: 100%;
    margin-left: -16px;
    padding: 12px 24px 0;
    background: white;
    box-shadow: 0 4px 8px 2px $shadow-blue;
    overflow: scroll;
    max-height: 85vh;
  }

  @media (min-width: $tablet-width) {
    left: 0;
    margin-left: 0px;
  }

  @media (min-width: $laptop-width) {
    margin-left: 16px;
  }

  @media (min-width: $desktop-width) {
    margin-left: 60px;
  }
}

.navbar {
  padding: 0;
  border-bottom: 1px solid $base-darker;
  transition: background-color .3s, border-bottom-color .3s;

  > a {
    font-weight: 500;
  }

  .container {
    padding: 12px 1rem;

    .sft-menus {
      transition: .3s ease-out;
    }

    &.sft-nav-xxl-container {
      @media (max-width: 992px) {
        max-width: 100%;
      }

      @media (min-width: 992px) {
        max-width: 990px;
      }

      @media (min-width: 1200px) {
        max-width: 1200px;
      }

      @media (min-width: 1300px) {
        max-width: 1240px;
      }
    }

    @media (max-width: $laptop-width) {
      ul.sft-menus {
        height: auto;
        padding: 0 8px 0 12px;
      }

      .btn-login {
        margin-bottom: 12px;
        margin-right: 0;
      }
    }
  }

  &.navbar-expand-lg{
    flex-flow: wrap;
  }

  .top-navbar {
    padding-top: 16px;
    padding-bottom: 0;
    justify-content: flex-end;
    gap: 24px;

    a {
      color: #5b6067;
      font-size: 12px;
      display: flex;
      align-items: center;

      i.fa-globe {
        font-size: 15px;
      }

      &:hover {
        color: $body-color;
      }

      &.sft-voucher-menu {
        display: flex;

        i.fa-badge-check {
          margin-right: 4px;
          color: #ff9000;
          font-size: 15px;
        }

        span {
          font-weight: 500;
        }

        &:hover {
          span {
            text-decoration: underline;
          }
        }
      }
    }

    & > a.sft-company-menu {
      font-weight: 500;
    }

    & > a.sft-company-menu, li > a span {
      &:hover {
        text-decoration: underline;
      }
    }

    li {
      list-style: none;
      line-height: 1;
    }

    ul.sft-top-nav-dropdown {
      margin-top: 8px;
      padding: 8px 0;
      min-width: inherit;
      border-radius: 4px;
      border: 1px solid #a5adb8;

      li.dropdown-item {
        padding: 8px 24px;

        &:active {
          background: #f8f9fa !important;
        }

        a {
          font-size: 14px;
          margin: 0;
        }
      }
    }
  }

  &.navbar-transparent {
    background-color: transparent;
    border-bottom: none;

    .navbar-toggler {
      i {
        color: white !important;
      }
    }

    a.sft-voucher-menu {
      i.fa-badge-check {
        color: #ffc31b;
      }
    }

    @media (min-width: $laptop-width) {
      li.nav-item > a {
        color: white !important;

        &:hover, &:focus {
          color: white !important;
        }

        &::after {
          background-color: white;
        }
      }

      .top-navbar {
        & > a {
          color: white;
        }

        li.nav-item {
          color: white;
        }
      }

      .btn-contact-sales {
        color: white;

        i {
          color: white;
        }
      }

      .btn-login {
        border-color: rgba(255, 255, 255, .4) !important;
        color: white !important;

        &:hover, &:focus {
          border-color: white !important;
          background-color: white !important;
          color: $primary !important;
        }
      }

      .navbar-nav > .btn-primary {
        border-color: rgba(255, 255, 255, .2) !important;
        background-color: rgba(255, 255, 255, .2) !important;
        color: white !important;

        &:hover, &:focus {
          border-color: white !important;
          background-color: white !important;
          color: $primary !important;
        }
      }
    }
  }
}

.navbar-nav .dropdown-menu {
  position: relative;
}

.nav-item {
  font-size: 14px;
  font-weight: 500;
  position: relative;

  &.sft-nav-item-service {
    padding: 1rem 0;
    margin-top: -1rem;

    @media (max-width: $laptop-width) {
      padding: 0;
      margin-top: 0;
    }
  }
}




#banner.container-fluid {
  position: relative;
  background-color: #a6e2ff;
  height: 42px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;

  a {
    color: $body-color;

    i {
      transition: all .3s ease-in-out;
    }

    &:hover {
      opacity: .85;

      i {
        transform: translateX(.25rem);
      }
    }
  }

  p {
    margin-bottom: 0;
    text-align: center;

    span {
      margin: 0 8px;
      font-weight: 500 !important;
    }

    @media (max-width: $laptop-width) {
      margin: 0 24px 0;
      word-break: keep-all;
    }
  }

  @media (max-width: $tablet-width) {
    height: 60px;
  }

  button.close {
    position: absolute;
    padding-left: 1rem;
    right: 2rem;
    top: 16px;
    font-size: 1.25rem;
    z-index: 3;

    @media (max-width: $tablet-width) {
      right: 12px;
      top: 20px;
    }
  }

  @media (max-width: 499px) {
    margin-top: 0;
  }
}

.btn-contact-sales {
  color: $body-color;
  font-weight: 500;

  i {
    margin-right: 6px;
    color: $primary;
  }

  &:hover {
    color: $primary;
    text-decoration: underline;
  }
}

.navbar-toggler {
  border: none;
  margin-right: -.75rem;

  i {
    font-size: 25px;
    color: #1e1f21;
  }
}

.sft-menus .nav-item {
  a {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      width: 0;
      height: 2px;
      background-color: $primary;
      bottom: -12px;
      left: 0;
      transition: width .3s;
      z-index: 1001;
    }
  }

  &:hover {
    & > a {
      color: $primary !important;

      @media(min-width: $laptop-width) {
        &::after {
          width: 100%;
        }
      }
    }
  }
}

.nav.sft-menus {
  height: 36px;
  line-height: 36px;
  flex: 1 1 auto;
  gap: 1.5rem;

  a {
    color: $body-color;
  }

  .dropdown-menu {
    display: block;
    visibility: hidden;
    line-height: 30px;
    top: 16px;
    opacity: 0;
    transform: translateY(-.5rem);
    transition: .3s ease-in-out;

    @media (min-width: $laptop-width) {
      &.dropdown-menu-services {
        margin-top: 0;
        width: 100vw;
        min-width: 100vw;
        border-radius: 0;
        position: fixed;
        top: 108px;

        .sft-service-column {
          a.dropdown-item {
            &::before {
              content: '';
              position: absolute;    width: calc(100% + 16px);
              height: calc(100% + 8px);
              left: -8px;
              top: -4px;
              border-radius: 8px;
              background-color: $base;
              z-index: -1;
              opacity: 0;
              transition: all .3s;
            }

            &:hover {
              &::before {
                opacity: 1;
              }
            }
          }

          &.sft-service-platform-column {
            padding: 0 0 0 60px;
            display: flex;
            flex-direction: column;
            position: relative;

            .sft-menu-service-overview {
              padding: 12px 0;
              border-radius: 4px;

              .sft-overview-img-container {
                height: 112px;
                margin-right: 16px;
                overflow: hidden;
                background-color: #0575e6;
                border-radius: 6px;
                position: relative;

                img {
                  position: absolute;
                  bottom: 0;
                  left: 0;
                }

                @media (max-width: $desktop-width) {
                  height: 120px;
                  margin-right: 0;

                  img {
                    width: 113%;
                  }
                }
              }
            }

            .sft-img-container {
              background-color: white;
            }

            &::after {
              content: '';
              position: absolute;
              height: calc(100% + 55px);
              width: 100vw;
              background-color: $base;
              left: 16px;
              top: -28px;
              z-index: -1;
            }
          }
        }
      }
    }

    &.dropdown-menu-solutions {
      display: flex;

      p.dropdown-menu-solution-name {
        margin: 4px 0 8px 24px;
        font-size: 12px;
        color: #8c909f;
        font-weight: 700;

        @media (max-width: $laptop-width) {
          margin: 16px 0 8px 24px;
        }
      }

      ul {
        padding-left: 0;
        min-width: 200px;
      }
    }

    &.dropdown-menu-services {
      span.sft-service-message {
        font-size: 13px;
        white-space: normal;
        word-break: keep-all;
        line-height: 1.5;
        color: #4f5051;

        @media (max-width: $desktop-width) {
          font-size: 12px;
        }
      }

      a.dropdown-item {
        padding: 8px;
        margin-bottom: 1rem;
      }

      ul {
        padding-left: 0;
      }

      .sft-service-item-container {
        line-height: 18px;

        p.sft-service-title {
          margin-bottom: 4px;
          transition: all .3s;
        }
      }

      @media (min-width: $laptop-width) {
        display: flex;
        padding-top: 16px;

        ul {
          padding-left: 0;
          min-width: 260px;
          max-width: 260px;
          width: 260px;
        }

        a.dropdown-item {
          padding: 12px 0;
          transition: .3s;

          &:hover {
            background-color: transparent;
            border-radius: 4px;

            p.sft-service-title {
              color: $primary;
            }
          }
        }

        .dropdown-menu-services-right {
          margin-top: 58px;
        }

        .sft-service-item-container {
          p.sft-service-title {
            font-weight: 500;
            transition: all .3s;
          }
        }
      }
    }

    &.show {
      display: block;
      visibility: visible;
      opacity: 1;
      transition: .3s ease-in-out, opacity .5s;
    }

    @media (max-width: $laptop-width) {
      &, &.dropdown-menu-solutions, &.dropdown-menu-services {
        display: none;
        margin-bottom: 12px;

        &.show {
          display: block;
          box-shadow: none;
          margin: 0;
          padding: 0;
        }
      }
    }

    .dropdown-item {
      padding-left: 24px;
      padding-right: 0;
      transition: all .3s;

      &:hover {
        color: $primary;
        background-color: white;

        p.sft-service-title {
          color: $primary;
        }
      }
    }

    .sft-service-column {
      .dropdown-item {
        p.sft-service-title {
          font-weight: 700;
          display: flex;
          align-items: center;
          margin-bottom: 6px;

          i {
            font-size: 12px;
            margin-left: 8px;
            margin-bottom: 3px;
            transition: transform .3s;
          }
        }

        &:hover {
          color: $body-color;

          p.sft-service-title i {
            transform: translateX(2px);
          }
        }
      }
    }

    @media (max-width: $laptop-width) {
      top: 8px;

      .sft-service-row {
        padding: 0;

        .sft-service-column {
          padding: 0;
        }
      }
    }

    .sft-img-container {
      padding: 4px;
      border-radius: 4px;
      background-color: $base;
      width: 32px;
      height: 32px;
      min-width: 32px;
      min-height: 32px;
      margin-right: 10px;
      line-height: 1;
      margin-top: -6px;
      transition: background-color .3s;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  @media (max-width: $laptop-width) {
    li.nav-item::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: #e3e8ee;
      left: 0;
      bottom: -12px;
    }
  }

  @media (min-width: $laptop-width) {
    .dropdown:hover {
      &.sft-hover {
        .dropdown-menu {
          visibility: visible;
          opacity: 1;
          transform: translateY(0);
          transition: .3s ease-in-out, opacity .5s;
        }
      }
    }
  }

  @media (min-width: $desktop-width) {
    gap: 2rem;
  }
}

.sft-btn-container {
  @media (max-width: $laptop-width) {
    padding-bottom: 1rem;
    position: sticky;
    bottom: 0;
    z-index: 1000;
    background: white;
    border-top: 1px solid $border-color;
    flex-direction: row;

    a.btn-contact-sales {
      color: $primary;
      border: 1px solid $primary;
      margin: 1rem 0;
      text-align: center;
      line-height: 1;
    }

    a.btn-login {
      width: calc(50% - 8px);
      margin-right: 16px !important;
      margin-bottom: 0 !important;
    }

    a.btn-signup {
      width: calc(50% - 8px);
    }
  }

  @media (min-width: $desktop-width) {
    a.btn-sm {
      padding: 1em 1.8em !important;
    }
  }

  @media (min-width: $laptop-width) {
    a.btn-sm {
      padding: 1em 1.2em;
    }
  }
}

.btn-login {
  margin-right: 8px;

  @media (min-width: $desktop-width) {
    margin-right: 18px;
  }
}
